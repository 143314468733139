/* video::-webkit-media-controls {
    display: none;
} */

.home-top-div {
  height: 90vh;
  background-size: cover;
  position: relative;
}

.home-navbar-div {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 100%;
}

.home-top-card {
  width: 35%;
  background: var(--primary-color);
  color: white;
  padding: 1rem 2rem;
  text-align: left;
  margin-top: -5rem;
  border-radius: 8px;
  position: relative;
  z-index: 10;
}

.home-top-know-more {
  white-space: pre;
  margin: 0;
}

.home-top-know-more-div {
  border: 1px solid white;
  padding: 4px 124px 4px 12px;
  width: 20%;
  border-radius: 20px;
}

.home-top-know-more-div :hover {
  cursor: pointer;
  color: var(--secondary-color);
}

.home__border {
  border-bottom: 6px solid #1597e5;
  position: fixed;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  z-index: 1000;
}
.card_div {
  width: 100%;
  margin: auto;
  padding: 2%;
  padding-top: 0%;
  padding-left: 3.7%;
  padding-right: 3.7%;
}

.home__container {
  max-width: 100%;
}

/* @media (max-width: 800px) {
  video {
    margin-top: 2%;
  }
} */

@media (max-width: 500px) {
  /* video {
    margin-top: 3%;
  } */
}

.rounded_border {
  border-radius: 5%;
}

.card_div > h2 {
  text-align: left;
  font-size: 40px;
  padding: 1% 0%;
  font-weight: bold;
  color: #393a4e;
  font-weight: 700;
  margin-bottom: 1%;
}

.home__img {
  border-radius: 5%;
  height: 400px;
  object-fit: cover;
}

.button {
  box-shadow: 0 5px 0 #0e0e0e;
  background-color: #2a2a2a !important;
  border-color: #2a2a2a !important;
  border-radius: 2rem !important;
  width: max-content;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600 !important;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block;
}

.button_div {
  padding: 8% 0%;
}
.card {
  border: none !important;
}
/* 
video::-webkit-media-controls-volume-slider {
    display: none;
}

video::-webkit-media-controls-mute-button {
    display: none;
}

video::-webkit-media-controls-timeline {
    display: none;
}

video::-webkit-media-controls-current-time-display {
    display: none;
} */
@media screen and (max-width: 480px) {
  .home-top-card {
    width: 75%;
  }

  .home-top-card-title {
    font-size: 1rem;
  }
}
