.sc_title_accent {
  padding: 15px;
  background: linear-gradient(
    135deg,
    #091353 0%,
    #3d56b2 20%,
    rgb(58 68 68) 82%,
    rgb(0 0 0) 100%
  ) !important;
}

.title_wrap_in {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.more {
  color: #3d56b2 !important;
  background-color: #f3f4f4 !important;
  font-size: 25px;
}

.yo {
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  color: #393a4e;
  padding: 1% 0;
  margin-bottom: 1%;
}

.tagline__btn {
  color: #3d56b2 !important;
  font-size: 20px;
  margin-top: 30px;
}

.outer {
  /* margin: auto; */
  padding: 2%;
  padding-left: 3.7%;
  padding-right: 3.7%;
}

.des_wrap {
  font-family: Verdana, Geneva, sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
}

.des_wrap > p {
  text-align: justify;
  line-height: 2rem;
  font-size: 20px;
}

@media (max-width: 960px) {
  .des_wrap > p {
    text-align: left;
  }
}

.sc_title_accent .title_wrap .sc_align_right {
  display: inline-block;
  width: 50%;
  padding: 53px 0;
  vertical-align: middle;
}

.sc_title_accent .title_wrap_in.sc_align_right .title_wrap {
  margin-left: 20%;
  margin-right: -15px;
  padding-left: 4%;
  background-color: #f3f4f4;
}

@media (max-width: 960px) {
  .sc_title_accent .title_wrap_in.sc_align_right .title_wrap {
    margin-left: 5%;
  }
}

@media (max-width: 767px) {
  .sc_title_accent .title_wrap_in.sc_align_right .title_wrap {
    margin-left: 0;
  }
}

@media (max-width: 400px) {
  .sc_title_accent .title_wrap_in.sc_align_right .title_wrap {
    margin-right: -15px;
    padding-left: 2%;
    background-color: #f3f4f4;
  }

  .sc_title_accent .title_wrap_in {
    padding: 35px 2px 25px;
    overflow: hidden;
  }
}

.sc_title_accent .title_wrap,
.sc_title_accent .des_wrap {
  width: 100%;
  /* max-width: 80%; */
  box-sizing: border-box;
  padding: 0px 0;
}

.sc_title_accent .title_wrap_in {
  text-align: left;
  background-color: #f3f4f4;
  padding: 40px 0 48px;
}

@media (max-width: 1279px) {
  .sc_title_accent .title_wrap_in {
    padding: 30px 0 32px;
  }
}

@media (min-width: 1279px) {
  .sc_title_accent .title_wrap_in {
    padding-left: 5%;
  }
}

@media (max-width: 1279px) {
  .sc_title_accent .title_wrap_in.sc_align_right .des_wrap {
    padding: 30px 10px 25px 4%;
  }
}

@media (max-width: 767px) {
  .sc_title_accent .title_wrap_in {
    padding: 35px 30px 25px;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .sc_title_accent .title_wrap_in.sc_align_right {
    display: block;
  }
}
