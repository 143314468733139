.strategy-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url('../strategy1.jpg');
  height: 90vh;
  background-size: cover no-repeat;
  position: relative;
  background-position: center;
  color: white;
}

.strategy-label{
  position: absolute;
  left: 4rem;
  bottom: 8px;
  font-weight: 700;
}

.strategy-evaluation-main{
  padding: 5% 4rem 1rem 4rem !important;
  text-align: left;
  color: var(--primary-color);
}

.strategy-evaluation-title{
  font-weight: 700;
  font-size: 1.5rem;
}

.evaluation-details{
  font-size: 1.2rem;
}

.evaluation-points-main{
  margin: 0 4rem 4rem ;
  background: #E4F2FF;
  padding: 3rem 20% 3rem 4rem;
  border-radius: 16px;
}
.evaluation-point{
  display: flex;
  gap:1rem;
  font-size: 1.2rem;
  text-align: left;
}

.evaluation-point-details{
  margin: 0;
}

@media screen and (max-width:772px){
  .strategy-evaluation-main{
    padding: 2rem 1.5rem !important;
  }

  .evaluation-points-main{
    margin: 0 !important;
    padding: 2rem 1.5rem !important;
  }

  .strategy-label{
    left:1.5rem !important;
    font-size: 1.5rem !important;
  }

  .strategy-evaluation-title{
    font-size: 1.2rem !important;
  }

  .evaluation-point{
    font-size: 1rem !important;
  }

  .evaluation-details{
    font-size: 1 !important;
  }
}































.str__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
}

.str__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.str__page {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

.str__para {
  font-size: 16px;
  font-weight: 600;
}

.str__list {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1%;
}

.str__img > img {
  width: 100%;
  height: 100%;
}

.mid__heading {
  color: #393a4e;
  /* margin-bottom: 3%;  */
  font-size: 33px;
  font-weight: 600;
  margin-top: 0.5%;
}

@media (max-width: 992px) {
  .str__img {
    margin-top: 1.5%;
  }

  .mid__heading {
    margin-top: 2%;
  }
}

.str__bott1 > p {
  font-size: 16px;
  font-weight: 600;
}

.str__list1 {
  background-color: #c3d6b8;
  padding: 22px 35px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.str__bott2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 772px) {
  .str__page {
    text-align: left;
  }

  .str__bott {
    text-align: left;
  }
}

@media (max-width: 992px) {
  .str__bott2 {
    margin-bottom: 1.5%;
  }
}

.str__bott2 > p {
  margin-top: 1%;
  text-align: justify;
  font-size: 16px;
  font-weight: 600;
}

.str__bott {
  margin-bottom: 3%;
}
