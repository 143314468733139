.heading > h1 {
  font-weight: 600;
  font-size: 33px;
  color: #393a4e;
  margin-top: 2%;
}

.about__p {
  font-size: 16px;
  font-weight: 600;
}

.history__img > img {
  width: 100%;
  height: 98%;
}

@media (min-width: 1650px) {
  .history__img > img {
    height: 94%;
  }
}

@media (min-width: 1730px) {
  .history__img > img {
    height: 88%;
  }
}

@media (min-width: 1770px) {
  .history__img > img {
    height: 84%;
  }
}

@media (min-width: 1844px) {
  .history__img > img {
    height: 77%;
  }
}

.about__outer {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.about__start {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  padding-top: 70px;
  margin-top: 2%;
}
