.firm-top-div{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 48.61%, rgba(0, 0, 0, 0.4) 100%),url('../strategy2.jpg');
  height: 90vh;
  background-size: cover no-repeat;
  position: relative;
  background-position: center;
  color: white;
}

#sector-cards-id{
  display: flex;
  flex-wrap: wrap !important;
  padding: 4rem ;
  gap: 4rem;
}

#sector-card-main-id{
  min-width: 40vw;
}

#sector-card-img-id{
  width: 100%;
  height: 300px;
}


@media screen and (max-width:772px) {

  #sector-card-main-id{
    width: 100%;
  }

  #sector-cards-id{
    padding: 1.5rem;
  }

  #sector-card-img-id{
    width: 100%;
    height: 300px;
  }
  
}
.firm__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #1597e5;
  width: 98%;
  position: fixed;
  text-align: justify;
  top: 120px;
  padding: 6px 5px 5px 25px;
  margin-bottom: 10px;
  margin-left: 1%;
  margin-right: 1%;
  z-index: 1000;
}

.firm__para {
  /* text-align: justify; */
  font-size: 16px;
  font-weight: 600;
}

.firm__left > h1 {
  margin-left: 1%;
  font-weight: 600;
  font-size: 33px;
}

.firm__page {
  max-width: 100%;
  padding-right: 3.7%;
  padding-left: 3.7%;
  text-align: justify;
  margin: 75px auto 30px;
}

@media (max-width: 772px) {
  .firm__page {
    text-align: left;
  }
}

.firm__img {
  border-radius: 5%;
  height: 400px;
  object-fit: cover;
}

.firm__heading {
  text-align: left;
  font-size: 33px;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.card {
  border: none !important;
  text-align: center;
}

.button_div {
  padding: 5% 0%;
}

.button {
  box-shadow: 0 5px 0 #0e0e0e;
  background-color: #2a2a2a !important;
  border-color: #2a2a2a !important;
  font-weight: 600 !important;
  border-radius: 2rem !important;
  width: max-content;
  /* font-family: verdana; */
  font-size: 16px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block;
  align-items: center;
}

/* .firm__about {
  margin-top: 2%;
} */

.history__img > img {
  width: 100%;
  height: 100%;
}
