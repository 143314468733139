.advisory__left {
  background-color: #0a152b;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: white;
  border-bottom: 6px solid #0b793a;
  text-align: justify;
  padding: 10px;
  padding-left: 25px;
}

.advisory__left > h1 {
  margin-left: 6%;
  font-weight: 600;
  font-size: 54px;
}

@media (max-width: 576px) {
  .advisory__left {
    padding-left: 0;
  }

  .advisory__left > h1 {
    margin-left: 2%;
    font-size: 45px;
  }
}

.advisory__right {
  width: 75%;
  margin: 50px auto;
  text-align: justify;
}

.advisory__right > p {
  text-align: justify;
  font-size: 16px;
  font-weight: 600;
}

.advisory__right > h2 {
  color: #393a4e;
  margin-bottom: 3%;
  font-size: 37px;
  font-weight: 700;
}

.advisory__list {
  background-color: #c3d6b8;
  width: 55%;
  padding: 25px 30px;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 1000px) {
  .advisory__list {
    width: 100%;
  }
}
