/* .vision__left {
  background-color: #0a152b;
  color: white;
  border-bottom: 6px solid #3d56b2;
  width: 100%;
  position: fixed;
  text-align: justify;
  top: 130px;
  padding: 5px;
  padding-left: 25px;
  margin-bottom: 10px;
} */

.vision__heading > h1 {
  font-weight: 600;
  font-size: 33px;
  color: #393a4e;
}

@media (min-width: 1650px) {
  .vision__heading > h1 {
    margin-top: -2.3%;
  }
}

@media (min-width: 1730px) {
  .vision__heading > h1 {
    margin-top: -3.8%;
  }
}

@media (min-width: 1770px) {
  .vision__heading > h1 {
    margin-top: -5%;
  }
}

@media (min-width: 1844px) {
  .vision__heading > h1 {
    margin-top: -7%;
  }
}

.vision__outer {
  max-width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

/* .vision__right {
  max-width: 100%;
  padding-right: 1%;
  padding-left: ;
  text-align: justify;
  margin-top: 2%;
} */

/* @media (max-width: 1070px) {
  .vision__right {
    padding: 80px 1% 2px 1%;
  }
} */

.vision__right > p {
  font-size: 16px;
  font-weight: 600;
}

.vision__right > h2 {
  color: #393a4e;
  font-size: 29px;
  font-weight: 700;
}
