@import url("https://fonts.googleapis.com/css2?family= Nunito:wght@300;400;500;600;700;800;900&display=swap");

body {

}

.App {
  text-align: center;
  font-family:  Nunito,"Montserrat", sans-serif;
}


