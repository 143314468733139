.app__button {
  background-color: #0d6efd;
  border: 0px;
  color: white;
  border-radius: 0px;
  box-shadow: none;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px;
}

#rcc-confirm-button {
  background-color: #0d6efd !important;
  color: white !important;
}

@media (max-width: 756px) {
  .cookie__content {
    display: block !important;
  }
  .app__button {
    margin: 15px 0 0 0 !important;
  }
}

@media (max-width: 756px) {
  .CookieConsent {
    display: block !important;
  }

  #rcc-confirm-button {
    margin-top: 0px !important;
  }
}
