.navbar__main {
  padding: 20px 0px;
  width: 98%;
  align-items: center;
  /* border-bottom: 6px solid #3d56b2; */
  margin-left: auto;
  margin-right: auto;
  background-color: #0a152b;
  color: white !important;
  height: auto !important;
}

.nav-item:hover .dropdown-menu {
  display: block !important;
}

/* @media (max-width: 550px) {
  .navbar__main {
    padding: 45px 0;
  }
} */

.nav__image {
  width: 220px;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-light .navbar-nav .nav-link {
  color: white !important;
  font-weight: 600;
  font-size: 15px;
}

.drop_main {
  padding: 0px 0px;
  margin: 0 0;
  background-color: #3d56b2;
}

.drop__item {
  padding: 20px;
  color: white;
  font-size: 16px;
  font-weight: 900;
}

.drop__item:hover {
  background-color: #9d9ca4;
}

.dropdown-menu {
  background-color: var(--primary-color);
}
/* #collasible-nav-dropdown {
  color: white;
  font-weight: bold;
  background-color: red;
} */
