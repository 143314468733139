.sectors-main {
  text-align: left;
  margin: 4rem;
  display: flex;
  flex-direction: column;
}

.title-placer {
  height: 5px;
  width: 6rem;
  background: var(--secondary-color);
  margin: 1rem 0 3rem;
}

.sector-cards {
  display: flex;
  gap: 2rem;
}

.sector-card-main {
  position: relative;
  flex: 1;
  color: white;
  border-radius: 10px;
  height: 60vh;
}

.sector-card-img {
  object-fit: cover;
  width: 100%;
  border-radius: inherit;
  height: 60vh;
}

.sector-card-know-more {
  position: absolute;
  top: 50%;
  left: 35%;
  display: none;
}

.sector-cards :hover {
  cursor: pointer;
  background: linear-gradient(
    0deg,
    rgba(0, 153, 204, 0.6),
    rgba(0, 153, 204, 0.6)
  );
}

.sector-cards :hover .sector-card-img {
  cursor: pointer;
  opacity: 0.3;
}

.sector-cards :hover .sector-card-know-more {
  display: block;
  cursor: pointer;
}

.sector-card-detail {
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  background-color: #2e80ce;
  border-radius: inherit;
}

.sector-card-id {
  margin: 0;
  padding: 8px 12px;
}

.sector-card-title {
  font-weight: 300;
  font-size: 20px;
  margin: 0;
}

@media screen and (max-width: 500px) {
  .sectors-main {
    margin: 0;
    padding: 2rem 1.5rem;
  }
  .sector-cards {
    flex-direction: column;
  }
  .sector-card-main {
    height: 30vh;
  }
  .sector-card-img {
    height: 30vh;
  }
}
